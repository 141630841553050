var site = site || {};
var Router = Router || {};

(function ($) {
  'use strict';

  /**
   * MPP filter behavior
   */
  Drupal.behaviors.mppFilterControls = {
    attach: function (context) {
      site.mppFilterControls.init(context);
    }
  };
})(jQuery);

site.mppFilterControls = {
  init: function (context) {
    if ($('.mpp-filter-img-v1').length < 1) {
      return null;
    }
    // Holds the state of the filter. skuAttr: values
    var filterState = {
      state: {},
      filters: {},
      masterFilters: {},
      // Add/remove based on if key/value exists
      setState: function (key, value) {
        // Has our key been set?
        if (!_.has(this.state, key)) {
          this.state[key] = [];
          this.state[key].push(value);
        }
        // Does this already exist (ie have we already filtered by it)?
        else if (_.contains(this.state[key], value)) {
          // remove value from array
          this.state[key] = _.without(this.state[key], value);
        } else {
          this.state[key].push(value);
        }
        // If key is empty, finally remove it from state object
        if (_.isEmpty(this.state[key])) {
          delete this.state[key];
        }
      },
      nukeState: function () {
        this.state = {};
      },
      isEmpty: function () {
        return _.isEmpty(this.state);
      }
    };
    // Routes
    var routes = {
      // Select full string in url
      '/filter': {
        '/([\\S]*)': function (urlFilterString) {
          // commented out console for ie

          var urlFilterChunks = urlFilterString.split('/');
          // commented out console for ie

          // AREA_OF_FACE=eyes,face,etc
          _.each(urlFilterChunks, function (urlFilterChunk) {
            var urlFilterChunkSplit = urlFilterChunk.split('=');
            var urlFilterKey = urlFilterChunkSplit[0]; // key, eg "AREA_OF_FACE"
            var urlFilterValues = urlFilterChunkSplit[1].split(','); // array of values, eg ["eyes","face","etc"]
            // commented out console for ie

            // SETSTATE HERE
          });
        }
      }
    };
    var router = Router(routes).configure({ strict: false }).init();
    // Filter UI
    var $filter = $('.grid-filter', context); // Context for selectors
    var filterItemClass = '.grid-filter__facet-item'; // Class on search terms
    var filterItemClassChecked = 'grid-filter__facet-item--checked'; // Used to add indicator class
    /*
     * Quickly show all products
     */
    var showAll = function () {
      _.each(site.productApp.products, function (product) {
        product.showProductDOM('.grid--mpp__item');
      });

      $filter.find(filterItemClass).removeClass('grid-filter__facet-item--count-zero');

      var $gridItemProducts = $('.grid--mpp__item:has(.product)');
      var $gridItemTouts = $('.grid--mpp__item:has(.node-elc-nodeblock)');

      // move touts to original positions in grid
      $gridItemTouts.each(function (i) {
        var $this = $(this);

        if (typeof $this.data('position') != 'undefined') {
          $this.detach().insertBefore($gridItemProducts[$this.data('position') - i]);
        }
      });
    };
    /*
     * Quickly show all products
     */
    var hideAll = function () {
      _.each(site.productApp.products, function (product) {
        product.hideProductDOM('.grid--mpp__item');
        // exclude filtering of complementary block by showing on hide
        product.showProductDOM('.mpp-complementary-products__item');
      });
    };

    /*
     * Initial filter state! Load up filters object with our values and counts
     */
    $('.grid-filter__facet').each(function () {
      $('.grid-filter__column-title', $(this)).click(function (e) {
        $(this).attr('aria-expanded', $(this).attr('aria-expanded') === 'true' ? 'false' : 'true');
        $(this).parent().toggleClass('grid-filter__column--open');
      });

      var facetKey = $(this).data('filter-key');

      filterState.filters[facetKey] = {};

      $('.grid-filter__facet-item', $(this)).each(function () {
        var facetItemName = $(this).data('filter-facet');
        var facetItemCount = $('.grid-filter__facet-item-count', $(this)).text();

        filterState.filters[facetKey][facetItemName] = facetItemCount;
      });
      filterState.masterFilters[facetKey] = $(this).data('filter-master') ? filterState.filters[facetKey] : '';
    });

    /*
     * Loop over each new Product collection, and update counts in the top
     */
    var filterCounts = function (productsList, $selectedFacet) {
      // Wipe out filter state
      _.each(filterState.filters, function (facets, key) {
        _.each(facets, function (count, facet) {
          filterState.filters[key][facet] = 0;
        });
      });

      // Filters counts logic
      _.each(productsList, function (prod) {
        // Full Product here, now loop through each key => facets
        _.each(filterState.filters, function (facets, key) {
          _.each(facets, function (count, facet) {
            if (prod.filterProductsByAttribute(key, [facet])) {
              filterState.filters[key][facet]++;
            }
          });
        });
      });

      // Every time a filter count is updated, update text
      filterTextUpdate($selectedFacet);
    };
    /*
     * Show our counts in filter area when counts change
     */
    var filterTextUpdate = function ($selectedFacet) {
      console.log(filterState.filters);
      _.each(filterState.filters, function (facets, key) {
        _.each(facets, function (count, facet) {
          // The actual clickable facet item
          var $facet = $("[data-filter-facet='" + facet + "']");

          // Set the new count text
          $('.grid-filter__facet-item-count', $facet).text(count);

          // If zero, add a parent class to indicate not to click
          if ($selectedFacet) {
            var isSameGroup = $selectedFacet.parent()[0] === $facet.parent()[0];

            if (count === 0 && !isSameGroup) {
              $facet.addClass('grid-filter__facet-item--count-zero');
            } else if (count !== 0 && !isSameGroup) {
              $facet.removeClass('grid-filter__facet-item--count-zero');
            }
          }
        });
      });
    };
    /*
     * Filter the data of visible products
     */
    var filterProducts = function () {
      // Used to store product ids
      var columnProducts = [];
      var facetsSpaces;

      // For each column in our filter
      _.forEach(filterState.state, function (facets, key) {
        facetsSpaces = _.map(facets, function (f) {
          return f.replace(/_/g, ' ');
        });

        // Array of arrays of PRODUCT_IDs
        columnProducts.push(
          // Find all full Products with the keys + values
          _.map(site.productApp.findProductsByProductAttribute(key, facetsSpaces), function (product) {
            // Return just the PRODUCT_ID
            return product.data.PRODUCT_ID;
          })
        );
      });

      // We should now end up with an array of arrays that looks something like this:
      // [ ['PROD123', 'PROD456', 'PROD789'], ['PROD456', 'PROD789'] ]
      // _.intersection helps us find common values in each group, representing the set

      // Reduce this array down to just those product ids that converge
      var reducedProductSetIds = _.intersection.apply(null, columnProducts);

      // We need the full Products to do stuff with, must flatten because
      // lookupProducts returns an array (we'd end up with an array of arrays
      // otherwise)
      return _.flatten(_.map(reducedProductSetIds, function (prodid) {
        return site.productApp.lookupProducts({ 'prodid': prodid });
      }));
    };

    // Click filter item
    $filter.on('click', filterItemClass, function () {
      var $selectedFacet = $(this);

      $selectedFacet.attr('aria-selected', $selectedFacet.attr('aria-selected') === 'false' ? 'true' : 'false');
      // The "columns" of the filter, ie "AREA_OF_FACE"
      var filterKey = $selectedFacet.parents('.grid-filter__facet').data('filter-key');
      // The actual value clicked, ie "EYES"
      var filterFacet = $selectedFacet.data('filter-facet');

      // Update the state of our filter
      filterState.setState(filterKey, filterFacet);

      // Hide all first
      hideAll();

      // Get our new reduced set
      var reducedProductSet = filterProducts();

      // visually show counts with new set
      filterCounts(reducedProductSet, $selectedFacet);

      var $gridItemTouts = $('.grid--mpp__item:has(.node-elc-nodeblock)');

      // move touts to end position in grid
      $gridItemTouts.each(function (i) {
        var $this = $(this);

        if (typeof $this.data('position') === 'undefined') {
          $this.data('position', $(this).index());
        }
      });
      // move touts to end position in grid
      var $gridList = $gridItemTouts.first().parent();

      $gridItemTouts.each(function (i) {
        $(this).appendTo($gridList);
      });

      // Show each of those products
      _.each(reducedProductSet, function (prod) {
        // Add/remove the classes on the product to hide/show
        prod.showProductDOM('.grid--mpp__item');
      });

      // Show all when filter is not checked
      if (filterState.isEmpty()) {
        showAll();
        filterCounts(site.productApp.products);
      }

      // Toggle state of check
      $selectedFacet.toggleClass(filterItemClassChecked);

      // Filter url generation
      filterUrlGenerator();
    });

    // Apply filter action from url values
    var urlFilterItems = function () {
      var baseLocationUrl = window.location.hash;

      if (baseLocationUrl.indexOf('#filters') >= 0) {
        window.location.hash = '';
        var filterUrl = baseLocationUrl.substr(9);
        var filterUrlString = filterUrl.split('/');

        for (var i = 0; i < filterUrlString.length; i++) {
          var urlFilterValue = filterUrlString[i];
          var $urlFilterItem = $('.grid-filter__facet-item[data-filter-facet="' + decodeURI(urlFilterValue) + '"]');

          if ($urlFilterItem.length > 0 && !$urlFilterItem.hasClass('grid-filter__facet-item--count-zero')) {
            $urlFilterItem.trigger('click');
          }
        }
      }
    };

    $(window).on('productApp:products_added', function () {
      urlFilterItems();
    });

    // Filter url generation
    var filterUrlGenerator = function () {
      var baseUrl = '';

      history.pushState('', document.title, window.location.pathname + window.location.search);
      if (!filterState.isEmpty()) {
        var currentHashUrl = window.location.hash;
        var currentBaseUrl = window.location.href;
        var filterType = '#filters/';

        if (currentBaseUrl.indexOf('#') >= 0) {
          filterType = 'filters/';
        }
        baseUrl = currentBaseUrl + filterType;
        var filterObjects = filterState.state;
        var filterFacetValues = '';

        for (var key in filterObjects) {
          var filterValues = filterObjects[key];
          var filterItems = '';

          for (var i = 0; i < filterValues.length; i++) {
            filterItems = filterValues[i];
            if (filterFacetValues.length > 0) {
              if (filterFacetValues.indexOf(filterItems) < 0) {
                filterFacetValues = filterFacetValues + '/' + filterItems;
              }
            } else {
              filterFacetValues = filterItems;
            }
          }
        }
        window.location.href = baseUrl + filterFacetValues;
      }
    };
    // ===========================================
    // new filter functionality
    var filters = [];

    window.filter = filters;

    var doneText, filteredByText, clearAllText;

    if (site.translations.product.mpp_filtering_done == null) {
      doneText = 'Done';
    } else {
      doneText = site.translations.product.mpp_filtering_done;
    }
    if (site.translations.product.mpp_filtering_filtered_by == null) {
      filteredByText = 'Filtered by';
    } else {
      filteredByText = site.translations.product.mpp_filtering_filtered_by;
    }
    if (site.translations.product.mpp_filtering_clear_all == null) {
      clearAllText = 'Clear all';
    } else {
      clearAllText = site.translations.product.mpp_filtering_clear_all;
    }

    var addDoneButton = function () {
      return $('.grid-filter_facet-list', context).each(function () {
        $(this).append("<li class='grid-filter__done-button-wrapper'>\
          <div class='grid-filter__done-button js-done-button' tabindex='0' role='button'>" + doneText + '</div></li>');
      });
    };
    var printFilterResults = function () {
      var filtersHtml = '';
      var partialFiltersHtml = [];

      $.each(filters, function (key, value) {
        filtersHtml += '<div class="grid-filter__results-item" data-filter-name="+ value +">\
         <div class="grid-filter__results-item-title js-remove-filter" data-filter-name="' + value + '" tabindex="0" role="link" aria-label="' + value + '">' + value.replace('_', ' ') + '</div>\
         <div class="grid-filter__results-item-remove js-remove-filter" data-filter-name="' + value + '" role="link" aria-label="' + value + '"><i class="icon-remove"></i></div>\
         </div>\
         ';
      });
      $('.grid-filter__results-list', context).html(filtersHtml);
      $('.grid-filter__column').each(function (index) {
        var _this = $(this);

        partialFiltersHtml[index] = '';

        $.each(filters, function (key, value) {
          if (_this.find('[data-filter-facet="' + value + '"]').length !== 0) {
            partialFiltersHtml[index] += '<div class="grid-filter__results-item" data-filter-name="' + value + '"">\
            <div class="grid-filter__results-item-title" data-filter-name="' + value + '">' + value.replace(/_/g, ' ') + '</div>\
            <div class="grid-filter__results-item-remove" data-filter-name="' + value + '">X</div>\
            </div>\
            ';
          }
        });
        $(this).find('.grid-filter__partial-results-list').html(partialFiltersHtml[index]);
      });
    };
    var checkFilterResults = function () {
      if (filters.length > 0) {
        $('.grid-filter__results', context).slideDown('fast');
        if ($(window).width() < 768) {
          $('.filter-popup-clear-all-button', context).css('display', 'inline-block');
        }
      } else {
        $('.grid-filter__results', context).slideUp('fast');
        if ($(window).width() < 768) {
          $('.filter-popup-clear-all-button', context).css('display', 'none');
        }
      }
    };
    var updateFilterResults = function (filter) {
      if (filters.indexOf(filter) > -1) {
        filters = filters.filter(function (arrayItem) {
          return arrayItem !== filter;
        });
      } else {
        filters.push(filter);
      }
    };
    var clearFilterResults = function (e) {
      filters = [];
      $('.grid-filter__facet-item--checked', context).removeClass('grid-filter__facet-item--checked');
      $('.grid-filter__results-list', context).html('');

      filterState.nukeState();
      window.location.hash = '';

      if (e.data.print) {
        e.data.print();
      }
      if (e.data.check) {
        e.data.check();
      }
      showAll();
      filterCounts(site.productApp.products);
    };
    var clearFilterResult = function (e) {
      e.preventDefault();
      var filterElement = $(this).attr('data-filter-name');

      $('.grid-filter__facet-item[data-filter-facet="' + filterElement + '"]', context).trigger('click');
      filterCounts(site.productApp.products);
    };
    var gridFilter = $('.grid-filter', context);

    filteredByText = Drupal.settings.globals_variables.foundation_filter_mobile ? filteredByText : filteredByText + ' :';
    var labelText = '<label class=\'grid-filter__results-label\'>' + filteredByText + '</label>';
    var gridFilterHTML = '<div class=\'grid-filter__results\'>' + labelText + '<div class=\'grid-filter__results-list\'></div>\
        <span class=\'grid-filter__results-clear js-clear-filters\' tabindex=\'0\' role=\'link\'>' + clearAllText + '</span>\
        </div>';
    var addFilterResults = function () {
      gridFilter.after(gridFilterHTML);
      $('.grid-filter__column').append("<div class='grid-filter__partial-results-list'></div>");
    };
    var toggleFilterFacetItem = function (e) {
      e.preventDefault();
      var element = $(this);

      // element.toggleClass('grid-filter__facet-item--checked');

      if (e.data.update) {
        e.data.update(element.attr('data-filter-facet'));
      }
      if (e.data.print) {
        e.data.print();
      }
      if (e.data.check) {
        e.data.check();
      }
    };
    var toggleFilterItem = function (e) {
      e.preventDefault();
      var element = $(this).closest('.grid-filter__column');

      element.siblings('.grid-filter__column').removeClass('grid-filter__column--active');
      element.toggleClass('grid-filter__column--active');
    };

    addDoneButton();
    addFilterResults();
    printFilterResults();
    checkFilterResults();

    $(document).on('click', '.grid-filter__column-title', toggleFilterItem);
    $(document).on('click', '.grid-filter__done-button', toggleFilterItem);
    $(document).on('click', '.grid-filter__results-item-title', clearFilterResult);
    $(document).on('click', '.grid-filter__results-item-remove', clearFilterResult);
    $(document).on('click', '.grid-filter__results-clear', {
      check: checkFilterResults,
      print: printFilterResults
    }, clearFilterResults);
    $(document).on('keydown', '.js-clear-filters', function (e) {
      if (site.getKeycode(e) === 13) {
        $(this).trigger('click', {
          check: checkFilterResults,
          print: printFilterResults
        });
      }
    });
    $(document).on('click', '.filter-popup-clear-all-button', {
      check: checkFilterResults,
      print: printFilterResults
    }, clearFilterResults);
    $(document).on('click', '.grid-filter__facet-item:not(:last-child)', {
      update: updateFilterResults,
      check: checkFilterResults,
      print: printFilterResults
    }, toggleFilterFacetItem);
    $('body').click(function (e) {
      if (!$(e.target).closest('.grid-filter__column').length) {
        if ($('.grid-filter__column--active').length && ($(window).width() > 767)) {
          $('.grid-filter__column--active').find('.grid-filter__done-button').trigger('click');
        }
      }
    });
    $('.mpp-filter-with-attributes-mobile', context).click(function (e) {
      $('.mpp-filter-with-attributes').show();
      if ($('.mpp-filter-with-attributes', context).hasClass('first-filter-column-opened') && ($(window).width() < 768)) {
        $('.grid-filter__column', context).first().find('.grid-filter__column-title').trigger('click');
      }
    });
    $('.filter-popup-done-button', context).click(function (e) {
      $('.mpp-filter-with-attributes').hide();
    });

    /**
     * MixItUp filter for filtered MPP
     * https://mixitup.kunkalabs.com/
     */
    var $miu_container = $('.grid--mpp:not(.grid-layout--masonry)', context);

    $miu_container.mixItUp({
      selectors: {
        target: '.grid--mpp__item'
      }
    });
  }
};
